/******************************
**
** Container/Gutters
**
******************************/
#home-slider .home-slider__slide__img-w, #home-slider .home-slider__slide__img, #home-slider .home-slider__slide__img-w:after, .article-card__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hilight-page .w-page .bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* gujarati */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 400;
  src: local("Hind Vadodara Regular"), local("HindVadodara-Regular"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neINzCKvrIcn5pbuuuriV9tTQInVrEsf.woff2) format("woff2");
  unicode-range: U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9, U+25CC, U+A830-A839;
}
/* latin-ext */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 400;
  src: local("Hind Vadodara Regular"), local("HindVadodara-Regular"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neINzCKvrIcn5pbuuuriV9tTQJzVrEsf.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 400;
  src: local("Hind Vadodara Regular"), local("HindVadodara-Regular"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neINzCKvrIcn5pbuuuriV9tTQJLVrA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* gujarati */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 600;
  src: local("Hind Vadodara SemiBold"), local("HindVadodara-SemiBold"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSE3xuW8-oBOL.woff2) format("woff2");
  unicode-range: U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9, U+25CC, U+A830-A839;
}
/* latin-ext */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 600;
  src: local("Hind Vadodara SemiBold"), local("HindVadodara-SemiBold"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSE3xuXo-oBOL.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 600;
  src: local("Hind Vadodara SemiBold"), local("HindVadodara-SemiBold"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSE3xuXQ-oA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* gujarati */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 700;
  src: local("Hind Vadodara Bold"), local("HindVadodara-Bold"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSCnwuW8-oBOL.woff2) format("woff2");
  unicode-range: U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9, U+25CC, U+A830-A839;
}
/* latin-ext */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 700;
  src: local("Hind Vadodara Bold"), local("HindVadodara-Bold"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSCnwuXo-oBOL.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 700;
  src: local("Hind Vadodara Bold"), local("HindVadodara-Bold"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSCnwuXQ-oA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "icomoon";
  src: url("../../static/fonts/icomoon/fonts/icomoon.eot?a2gchp");
  src: url("../../static/fonts/icomoon/fonts/icomoon.eot?a2gchp#iefix") format("embedded-opentype"), url("../../static/fonts/icomoon/fonts/icomoon.ttf?a2gchp") format("truetype"), url("../../static/fonts/icomoon/fonts/icomoon.woff?a2gchp") format("woff"), url("../../static/fonts/icomoon/fonts/icomoon.svg?a2gchp#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i:not(.mce-ico) {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-espace-client:before {
  content: "\e972";
}

.icon-compteur:before {
  content: "\e96f";
}

.icon-alert:before {
  content: "\e970";
}

.icon-chart:before {
  content: "\e971";
}

.cooking:before {
  content: "\e963";
}

.window:before {
  content: "\e964";
}

.fire:before {
  content: "\e965";
}

.termostat:before {
  content: "\e966";
}

.shower:before {
  content: "\e967";
}

.water-sink:before {
  content: "\e968";
}

.worker:before {
  content: "\e95b";
}

.heating-water:before {
  content: "\e96a";
}

.air:before {
  content: "\e96b";
}

.temperature-list:before {
  content: "\e96c";
}

.blinds-raised:before {
  content: "\e96d";
}

.window-frame:before {
  content: "\e96e";
}

.icon-worker-2:before {
  content: "\e962";
}

.icon-graduation:before {
  content: "\e960";
}

.icon-handshake:before {
  content: "\e961";
}

.icon-newspaper:before {
  content: "\e959";
}

.icon-hydrogen:before {
  content: "\e957";
}

.icon-recycling:before {
  content: "\e958";
}

.icon-gas-station-full:before {
  content: "\e94f";
}

.icon-big-arrowup:before {
  content: "\e950";
}

.icon-thumbup:before {
  content: "\e951";
}

.icon-big-moins:before {
  content: "\e952";
}

.icon-big-plus:before {
  content: "\e953";
}

.icon-camera:before {
  content: "\e954";
}

.icon-big-euro:before {
  content: "\e955";
}

.icon-heart:before {
  content: "\e956";
}

.icon-param:before {
  content: "\e94e";
}

.icon-pause:before {
  content: "\e94d";
}

.icon-lightbulb1:before {
  content: "\e94b";
}

.icon-tools:before {
  content: "\e94c";
}

.icon-assignment:before {
  content: "\e949";
}

.icon-quote1:before {
  content: "\e94a";
}

.icon-document:before {
  content: "\e946";
}

.icon-pdf:before {
  content: "\e947";
}

.icon-photo:before {
  content: "\e948";
}

.icon-callback:before {
  content: "\e944";
}

.icon-phonecall:before {
  content: "\e945";
}

.icon-quote:before {
  content: "\e943";
}

.icon-gnv:before {
  content: "\e942";
}

.icon-add:before {
  content: "\e900";
}

.icon-addlocation:before {
  content: "\e901";
}

.icon-arrowback:before {
  content: "\e902";
}

.icon-arrowbotom:before {
  content: "\e903";
}

.icon-arrowright:before {
  content: "\e904";
}

.icon-arrowup:before {
  content: "\e905";
}

.icon-avatar:before {
  content: "\e906";
}

.icon-bullet:before {
  content: "\e907";
}

.icon-cards:before {
  content: "\e908";
}

.icon-chat:before {
  content: "\e909";
}

.icon-check:before {
  content: "\e90a";
}

.icon-check-filled:before {
  content: "\e90b";
}

.icon-close:before {
  content: "\e90c";
}

.icon-comments:before {
  content: "\e90d";
}

.icon-creditcard:before {
  content: "\e90e";
}

.icon-date:before {
  content: "\e90f";
}

.icon-date-1:before {
  content: "\e910";
}

.icon-delete:before {
  content: "\e911";
}

.icon-doc:before {
  content: "\e912";
}

.icon-download:before {
  content: "\e913";
}

.icon-edit:before {
  content: "\e914";
}

.icon-electric:before {
  content: "\e915";
}

.icon-electricmeter:before {
  content: "\e916";
}

.icon-euros:before {
  content: "\e917";
}

.icon-eye:before {
  content: "\e918";
}

.icon-eye-blind:before {
  content: "\e919";
}

.icon-facebook:before {
  content: "\e91a";
}

.icon-filter:before {
  content: "\e91b";
}

.icon-home:before {
  content: "\e938";
}

.icon-info:before {
  content: "\e939";
}

.icon-instagram:before {
  content: "\e93a";
}

.icon-leaf:before {
  content: "\e93b";
}

.icon-lightbulb:before {
  content: "\e93c";
}

.icon-link:before {
  content: "\e93d";
}

.icon-linkedin:before {
  content: "\e93e";
}

.icon-list:before {
  content: "\e93f";
}

.icon-mail:before {
  content: "\e940";
}

.icon-menu:before {
  content: "\e941";
}

.icon-miniarrowbottom:before {
  content: "\e91c";
}

.icon-miniarrowleft:before {
  content: "\e91d";
}

.icon-miniarrowright:before {
  content: "\e91e";
}

.icon-miniarrowup:before {
  content: "\e91f";
}

.icon-minicards:before {
  content: "\e920";
}

.icon-minimize:before {
  content: "\e921";
}

.icon-more:before {
  content: "\e922";
}

.icon-mylocation:before {
  content: "\e923";
}

.icon-navigation:before {
  content: "\e924";
}

.icon-partners:before {
  content: "\e925";
}

.icon-phone:before {
  content: "\e926";
}

.icon-pinterest:before {
  content: "\e927";
}

.icon-place:before {
  content: "\e928";
}

.icon-play:before {
  content: "\e929";
}

.icon-plug:before {
  content: "\e92a";
}

.icon-question:before {
  content: "\e92b";
}

.icon-reload:before {
  content: "\e92c";
}

.icon-schedule:before {
  content: "\e92d";
}

.icon-search:before {
  content: "\e92e";
}

.icon-searchfournisseur:before {
  content: "\e92f";
}

.icon-searchinfo:before {
  content: "\e930";
}

.icon-searchinstal:before {
  content: "\e931";
}

.icon-services:before {
  content: "\e932";
}

.icon-share:before {
  content: "\e933";
}

.icon-touch:before {
  content: "\e934";
}

.icon-twitter:before {
  content: "\e935";
}

.icon-warning:before {
  content: "\e936";
}

.icon-work:before {
  content: "\e937";
}

.icon-cycle:before {
  content: "\e95a";
}

.icon-worker:before {
  content: "\e95b";
}

.icon-building:before {
  content: "\e95c";
}

.icon-equality:before {
  content: "\e95d";
}

.icon-feuille:before {
  content: "\e95e";
}

.icon-hard-hat:before {
  content: "\e95f";
}

/* ===================
	    Colors
/* ===================*/
/* ===================
	    Fonts
/* ===================*/
/* ===================
    Config
/* ===================*/
/* ===================
    Dauphine var default
/* ===================*/
/* ===================
    Margin & padding
/* ===================*/
/* ===================
    FlexContent
/* ===================*/
/* ===================
    Animation
/* ===================*/
.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
}

/* ===================
	    Fonts
/* ===================*/
/* ===================
	    Icomoon
/* ===================*/
/* ===================
	    Background
/* ===================*/
/* ===================
	    Layout
/* ===================*/
.w-commitment .commitment-text .caption, #dedi-area .caption, #home-highlight .row .distributor .caption, .hilight-page .w-page .caption {
  font-size: 17px;
  line-height: 28px;
  font-weight: normal;
}

.climat .gaz .data .source {
  font-size: 15px;
  line-height: normal;
  font-weight: normal;
}

.hilight-page .w-page {
  position: relative;
  padding: 155px 25px 25px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  overflow: hidden;
}
.hilight-page .w-page:before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  left: 0;
  top: 0;
  transition: all 250ms ease-out;
  z-index: 0;
}
.hilight-page .w-page:after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(40deg, #54388B 0%, #554894 32%, #5773AC 60%, #5AB7D3 94%, #58C0D8 100%);
  position: absolute;
  left: 0;
  top: 0;
  transition: all 250ms ease-out;
  z-index: -1;
  opacity: 0;
  transition: all 250ms ease-out;
}
@media (min-width: 720px) {
  .hilight-page .w-page {
    padding-top: 265px;
  }
}
@media (min-width: 960px) {
  .hilight-page .w-page {
    padding-top: 165px;
  }
}
@media (min-width: 1280px) {
  .hilight-page .w-page {
    padding-top: 265px;
  }
}
@media (min-width: 1600px) {
  .hilight-page .w-page {
    padding-top: 400px;
  }
}
.hilight-page .w-page .bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  transform: scale(1, 1);
  transition: all 300ms cubic-bezier(0.04, 0.19, 0.56, 0.99);
}
.hilight-page .w-page .headline {
  position: relative;
  z-index: 1;
}
.hilight-page .w-page .title {
  padding-bottom: 10px;
  position: relative;
  z-index: 1;
}
.hilight-page .w-page .caption {
  display: none;
  position: relative;
  z-index: 1;
}
@media (min-width: 720px) {
  .hilight-page .w-page .caption {
    display: block;
    padding-bottom: 25px;
  }
}
.hilight-page .w-page .btn {
  align-self: flex-end;
}
@media (min-width: 960px) {
  .hilight-page .w-page:hover .bg {
    transform: scale(1.1);
  }
  .hilight-page .w-page:hover:after {
    opacity: 0.7;
  }
}

#articles-sections {
  position: relative;
  padding: 40px 0;
}
@media (min-width: 480px) {
  #articles-sections {
    padding-bottom: 20px;
  }
}
@media (min-width: 720px) {
  #articles-sections {
    padding: 60px 0 20px;
  }
}
@media (min-width: 1280px) {
  #articles-sections {
    padding: 80px 0 35px;
  }
}
#articles-sections:before {
  content: "";
  top: 0;
  bottom: 0;
  left: calc(((100% - 720px) / 2) + 36px);
  width: 1px;
  background-color: #C7CBCF;
  position: absolute;
  display: none;
}
@media (min-width: 720px) {
  #articles-sections:before {
    display: block;
  }
}
@media (min-width: 960px) {
  #articles-sections:before {
    left: calc(((100% - 940px) / 2) + 24px);
  }
}
@media (min-width: 1280px) {
  #articles-sections:before {
    left: calc(((100% - 1200px) / 2) + 24px);
  }
}
@media (min-width: 1600px) {
  #articles-sections:before {
    left: calc(((100% - 1400px) / 2) + 24px);
  }
}
@media (min-width: 720px) {
  #articles-sections .wrap {
    margin-left: 8.3333333333%;
  }
}

#articles-sections__head {
  margin-bottom: 30px;
}
#articles-sections__head .title {
  margin-bottom: 10px;
}
@media (min-width: 480px) {
  #articles-sections__head .title {
    margin-bottom: 0;
  }
}
@media (min-width: 480px) {
  #articles-sections__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #articles-sections__head .btn {
    position: relative;
    top: 10px;
  }
}

.articles-list {
  display: flex;
  flex-direction: column;
}
@media (min-width: 480px) {
  .articles-list {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 1280px) {
  .articles-list.row {
    margin-right: -22px;
    margin-left: -22px;
  }
  .articles-list.row .article-card {
    padding-left: 22px;
    padding-right: 22px;
    margin-bottom: 45px;
    min-height: 198px;
  }
}

.article-card {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  padding-left: 6px;
  padding-right: 6px;
}
@media (min-width: 720px) {
  .article-card {
    padding-left: 12px;
  }
}
@media (min-width: 720px) {
  .article-card {
    padding-right: 12px;
  }
}
@media (min-width: 720px) {
  .article-card {
    margin-bottom: 40px;
  }
}
.article-card:last-child {
  margin-bottom: 0;
}
@media (min-width: 480px) {
  .article-card:last-child {
    margin-bottom: 20px;
  }
}
@media (min-width: 720px) {
  .article-card:last-child {
    margin-bottom: 40px;
  }
}
.article-card__in {
  box-shadow: 2px 12px 30px -5px rgba(28, 26, 54, 0.25);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
@media (min-width: 960px) {
  .article-card__in {
    flex-direction: row;
  }
}
.article-card__in:hover .article-card__title {
  color: #3BC6E5;
}
.article-card__in:hover .btn {
  color: #3BC6E5;
}
.article-card__in:hover .btn:before {
  opacity: 1;
}
.article-card__img-w {
  padding-top: 54.8%;
  height: 0;
  background-color: #1c1a36;
  position: relative;
}
@media (min-width: 960px) {
  .article-card__img-w {
    height: 100%;
    width: 45%;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
}
@media (min-width: 1280px) {
  .article-card__img-w {
    width: 35%;
    padding-left: 0;
    padding-right: 0;
  }
}
.article-card__img {
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.article-card__video {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 960px) {
  .article-card__video {
    width: 55px;
    height: 55px;
  }
}
.article-card__video .icon {
  font-size: 35px;
  background: -webkit-gradient(linear, right bottom, right top, from(#3BC6E5), to(#54388C));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #3BC6E5;
}
.article-card__date {
  text-transform: uppercase;
  color: #616B75;
  font-size: 15px;
  line-height: 15px;
}
.article-card__title {
  font-weight: 600;
  font-size: 19px;
  line-height: 26px;
  min-height: 60px;
  margin-bottom: 20px;
  color: #1c1a36;
  transition: all 250ms ease-out;
}
@media (min-width: 480px) {
  .article-card__title {
    flex: 1 0 auto;
  }
}
@media (min-width: 960px) {
  .article-card__title {
    font-size: 21px;
    line-height: 28px;
  }
}
.article-card__text {
  padding: 20px;
}
@media (min-width: 480px) {
  .article-card__text {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
}
@media (min-width: 960px) {
  .article-card__text {
    flex: auto;
    width: 55%;
    padding-left: 0;
    padding-right: 0;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 1280px) {
  .article-card__text {
    width: 65%;
    padding-left: 0;
    padding-right: 0;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 480px) {
  .article-card {
    width: 50%;
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (min-width: 480px) and (min-width: 720px) {
  .article-card {
    padding-left: 12px;
  }
}
@media (min-width: 480px) and (min-width: 720px) {
  .article-card {
    padding-right: 12px;
  }
}

@media (min-width: 960px) {
  .home #articles-sections {
    padding-top: 40px;
  }
}
.home #articles-sections:before {
  display: none;
}
.home #articles-sections .wrap {
  margin-left: 0;
}

/* Safari 10.1+ */
.article-card__video .icon {
  border-radius: 50%;
  overflow: hidden;
}

.article-card__video .icon:before {
  background: -webkit-gradient(linear, right bottom, right top, from(#3BC6E5), to(#54388C));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #3BC6E5;
  width: 55px;
  height: 55px;
  display: inline-block;
  padding-top: 10px;
  padding-left: 3px;
  text-align: center;
}

@media (max-width: 959.98px) {
  #home-highlight .container {
    padding: 0;
  }
}
@media (min-width: 960px) {
  #home-highlight .row {
    margin-top: -60px;
    padding-bottom: 80px;
  }
}
#home-highlight .row .distributor {
  padding: 40px 16px;
  text-align: center;
  background-image: url("../../static/img/bg/home_highlight.jpg");
  background-repeat: no-repeat;
  background-size: 60%;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.15);
}
@media (min-width: 720px) {
  #home-highlight .row .distributor {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}
@media (min-width: 960px) {
  #home-highlight .row .distributor {
    padding: 60px 120px;
    width: 66.6666666667%;
    padding-left: 6px;
    padding-right: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  #home-highlight .row .distributor {
    padding-left: 12px;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  #home-highlight .row .distributor {
    padding-right: 12px;
  }
}
#home-highlight .row .distributor .titles {
  padding-bottom: 15px;
}
#home-highlight .row .distributor .btn-w {
  margin-top: 25px;
}
#home-highlight .my-space {
  z-index: 1;
}
@media (min-width: 960px) {
  #home-highlight .my-space {
    width: 33.3333333333%;
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  #home-highlight .my-space {
    padding-left: 12px;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  #home-highlight .my-space {
    padding-right: 12px;
  }
}
#home-highlight .my-space .w-page {
  padding: 75px 16px;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.15);
}
@media (min-width: 960px) {
  #home-highlight .my-space .w-page {
    padding: 38px 20px;
  }
}
@media (min-width: 1280px) {
  #home-highlight .my-space .w-page {
    padding: 68px 40px;
  }
}
#home-highlight .my-space .w-page:before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(360deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
}
#home-highlight .my-space .w-page .headline {
  margin: 0 auto;
}
#home-highlight .my-space .w-page .title {
  z-index: 1;
  margin-bottom: 24px;
  margin-top: 4px;
  text-align: center;
}
#home-highlight .my-space .w-page .btn {
  align-self: center;
}

#home-slider {
  background-color: #1c1a36;
  height: 83.5vh;
  min-height: 480px;
  position: relative;
  overflow: hidden;
  max-height: 576px;
}
@media (min-width: 720px) and (max-width: 959.98px) {
  #home-slider {
    height: 43.5vh;
  }
}
@media (min-width: 960px) {
  #home-slider {
    max-height: 720px;
  }
}
#home-slider [js-slider] {
  opacity: 0;
}
#home-slider [js-slider].ready {
  opacity: 1;
}
#home-slider .home-slider__slide {
  height: 83.5vh;
  min-height: 480px;
  max-height: 576px;
  position: relative;
  display: flex;
  align-items: flex-end;
}
@media (min-width: 720px) and (max-width: 959.98px) {
  #home-slider .home-slider__slide {
    height: 43.5vh;
  }
}
@media (min-width: 960px) {
  #home-slider .home-slider__slide {
    align-items: flex-end;
    max-height: 720px;
  }
}
#home-slider .home-slider__slide__in {
  z-index: 2;
  position: relative;
  padding-left: 85px;
  padding-bottom: 180px;
}
@media (min-width: 720px) {
  #home-slider .home-slider__slide__in {
    padding-left: 150px;
    padding-bottom: 216px;
  }
}
@media (min-width: 960px) {
  #home-slider .home-slider__slide__in {
    padding-bottom: 100px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 8.3333333333%;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  #home-slider .home-slider__slide__in {
    max-width: 720px;
  }
}
@media (min-width: 960px) and (min-width: 960px) {
  #home-slider .home-slider__slide__in {
    max-width: 940px;
  }
}
@media (min-width: 960px) and (min-width: 1280px) {
  #home-slider .home-slider__slide__in {
    max-width: 1200px;
  }
}
@media (min-width: 960px) and (min-width: 1600px) {
  #home-slider .home-slider__slide__in {
    max-width: 1400px;
  }
}
#home-slider .home-slider__slide__title {
  margin-bottom: 20px;
  max-width: 500px;
}
@media (min-width: 720px) {
  #home-slider .home-slider__slide__title {
    margin-bottom: 30px;
  }
}
#home-slider .home-slider__slide__title__top, #home-slider .home-slider__slide__title__main {
  transform: translate3d(0, 20px, 0);
  opacity: 0;
}
#home-slider .home-slider__slide__btn-w {
  transform: translate3d(0, -20px, 0);
  opacity: 0;
}
#home-slider .home-slider__slide__img-w:after {
  content: "";
  z-index: 2;
  background-color: rgba(28, 26, 54, 0.85);
}
@media (min-width: 960px) {
  #home-slider .home-slider__slide__img-w:after {
    background: linear-gradient(180deg, rgba(28, 26, 54, 0) 12.17%, rgba(28, 26, 54, 0) 12.17%, rgba(28, 26, 54, 0.65) 48.48%, rgba(28, 26, 54, 0.85) 70.71%, rgba(28, 26, 54, 0.9) 100%);
  }
}
#home-slider .home-slider__slide__img {
  z-index: 2;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 250ms ease-out;
  transform-origin: bottom;
  transform: scale(1.2);
}
#home-slider .home-slider__slide.active .home-slider__slide__img {
  transform: scale(1);
  transition: all 800ms ease-out;
}
#home-slider .home-slider__slide.active .home-slider__slide__title__top, #home-slider .home-slider__slide.active .home-slider__slide__title__main, #home-slider .home-slider__slide.active .home-slider__slide__btn-w {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition-delay: 100ms;
  transition: all 500ms ease-out;
  transition-property: opacity, transform;
  transition-delay: 300ms;
}
#home-slider .home-slider__slide.leave .home-slider__slide__img {
  transform: scale(1);
}
#home-slider.slick-dotted.slick-slider {
  margin-bottom: 0;
}
#home-slider .slick-dots {
  bottom: 180px;
  overflow: hidden;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  pointer-events: none;
  padding-top: 110px;
  padding-left: 12px;
}
@media (min-width: 720px) {
  #home-slider .slick-dots {
    bottom: 0;
    padding-left: 24px;
  }
}
@media (min-width: 960px) {
  #home-slider .slick-dots {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: calc(50% - 22px);
    transform: translate3d(-50%, 0, 0);
    padding-top: 200px;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  #home-slider .slick-dots {
    max-width: 720px;
  }
}
@media (min-width: 960px) and (min-width: 960px) {
  #home-slider .slick-dots {
    max-width: 940px;
  }
}
@media (min-width: 960px) and (min-width: 1280px) {
  #home-slider .slick-dots {
    max-width: 1200px;
  }
}
@media (min-width: 960px) and (min-width: 1600px) {
  #home-slider .slick-dots {
    max-width: 1400px;
  }
}
@media (min-width: 1280px) {
  #home-slider .slick-dots {
    padding-top: 150px;
  }
}
#home-slider .slick-dots li {
  pointer-events: auto;
  margin: 0 0 -5px;
  width: 48px;
  height: 48px;
  position: relative;
}
#home-slider .slick-dots li.active .page {
  border-color: rgba(255, 255, 255, 0.25);
  color: #3BC6E5;
}
#home-slider .slick-dots li.active .page .arc {
  opacity: 1;
}
#home-slider .slick-dots li.active .page .arc__left {
  top: 0;
}
#home-slider .slick-dots li.active .page .arc__right {
  bottom: 0;
}
#home-slider .slick-dots li:first-child:before {
  content: "";
  width: 1px;
  background-color: rgba(255, 255, 255, 0.45);
  position: absolute;
  bottom: calc(100% + 10px);
  top: -9999px;
  left: 50%;
}
#home-slider .slick-dots li:last-child:after {
  content: "";
  width: 1px;
  background-color: rgba(255, 255, 255, 0.45);
  position: absolute;
  top: calc(100% + 10px);
  bottom: -9999px;
  left: 50%;
}
#home-slider .slick-dots li:hover .page {
  color: #3BC6E5;
}
#home-slider .slick-dots .page {
  font-weight: bold;
  color: white;
  font-size: 18px;
  line-height: 18px;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  transition: all 250ms ease-out;
  position: relative;
}
#home-slider .slick-dots .page .arc {
  position: absolute;
  transition: all 250ms ease-out;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  opacity: 0;
  transform-origin: center;
}
#home-slider .slick-dots .page .arc__left, #home-slider .slick-dots .page .arc__right {
  position: absolute;
  top: 0;
  width: 24px;
  overflow: hidden;
}
#home-slider .slick-dots .page .arc__left:before, #home-slider .slick-dots .page .arc__right:before {
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: #3BC6E5 solid 1px;
  border-radius: 50%;
}
#home-slider .slick-dots .page .arc__left {
  top: 48px;
  left: 0;
  bottom: 0;
  transform: rotate(180deg);
  transition: top 2400ms linear;
  transition-delay: 2400ms;
}
#home-slider .slick-dots .page .arc__right {
  right: 0;
  bottom: 48px;
  transition: bottom 2400ms linear;
}
#home-slider .slick-dots .page:before {
  content: none;
}
#home-slider #home-slider-cta {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 20px;
  padding-left: 12px;
  pointer-events: none;
}
@media (min-width: 720px) {
  #home-slider #home-slider-cta {
    padding-left: 150px;
    padding-bottom: 45px;
  }
}
@media (min-width: 960px) {
  #home-slider #home-slider-cta {
    padding-left: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 105px;
    overflow: hidden;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  #home-slider #home-slider-cta {
    max-width: 720px;
  }
}
@media (min-width: 960px) and (min-width: 960px) {
  #home-slider #home-slider-cta {
    max-width: 940px;
  }
}
@media (min-width: 960px) and (min-width: 1280px) {
  #home-slider #home-slider-cta {
    max-width: 1200px;
  }
}
@media (min-width: 960px) and (min-width: 1600px) {
  #home-slider #home-slider-cta {
    max-width: 1400px;
  }
}
#home-slider #home-slider-cta .home-slider-cta__item {
  margin-bottom: 10px;
}
@media (min-width: 960px) {
  #home-slider #home-slider-cta .home-slider-cta__item {
    width: 33.3333333333%;
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  #home-slider #home-slider-cta .home-slider-cta__item {
    padding-left: 12px;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  #home-slider #home-slider-cta .home-slider-cta__item {
    padding-right: 12px;
  }
}
@media (min-width: 1280px) {
  #home-slider #home-slider-cta .home-slider-cta__item {
    width: 25%;
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (min-width: 1280px) and (min-width: 720px) {
  #home-slider #home-slider-cta .home-slider-cta__item {
    padding-left: 12px;
  }
}
@media (min-width: 1280px) and (min-width: 720px) {
  #home-slider #home-slider-cta .home-slider-cta__item {
    padding-right: 12px;
  }
}
#home-slider #home-slider-cta .home-slider-cta__link {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: auto;
  font-size: 17px;
  line-height: 17px;
  transition: all 250ms ease-out;
}
#home-slider #home-slider-cta .home-slider-cta__link:hover {
  color: #3BC6E5;
}
#home-slider #home-slider-cta .home-slider-cta__link:hover .home-slider-cta__icon {
  border-color: #3BC6E5;
}
#home-slider #home-slider-cta .home-slider-cta__icon {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.25);
  margin-right: 20px;
  border-radius: 50%;
  transition: border-color 200ms ease-out;
}
#home-slider #home-slider-cta .home-slider-cta__icon .icon {
  font-size: 24px;
  line-height: 24px;
}
#home-slider #home-slider-cta .home-slider-cta__txt {
  position: relative;
}
@media (min-width: 960px) {
  #home-slider #home-slider-cta .home-slider-cta__txt:after {
    content: "";
    position: absolute;
    height: 1px;
    left: calc(100% + 20px);
    right: -9999px;
    top: 50%;
    background-color: rgba(255, 255, 255, 0.25);
  }
}

@media (min-height: 600px) and (min-width: 960px) {
  #home-slider .slick-dots {
    padding-top: 250px;
  }
}
@media (min-height: 700px) and (min-width: 960px) {
  #home-slider .slick-dots {
    padding-top: 350px;
  }
}
@media (min-height: 700px) and (min-width: 960px) {
  #home-slider .slick-dots {
    padding-top: 350px;
  }
}
@media (min-height: 800px) and (min-width: 960px) {
  #home-slider .slick-dots {
    padding-top: 420px;
  }
}
@media (min-height: 600px) and (min-width: 1280px) {
  #home-slider .slick-dots {
    padding-top: 150px;
  }
}
@media (min-height: 700px) and (min-width: 1280px) {
  #home-slider .slick-dots {
    padding-top: 250px;
  }
}
@media (min-height: 800px) and (min-width: 1280px) {
  #home-slider .slick-dots {
    padding-top: 350px;
  }
}
#dedi-area {
  position: relative;
}
@media (min-width: 960px) {
  #dedi-area:before {
    content: "";
    top: 0;
    bottom: 0;
    left: calc(((100% - 720px) / 2) + 36px);
    width: 1px;
    background-color: #C7CBCF;
    position: absolute;
    display: none;
    bottom: 34%;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  #dedi-area:before {
    display: block;
  }
}
@media (min-width: 960px) and (min-width: 960px) {
  #dedi-area:before {
    left: calc(((100% - 940px) / 2) + 24px);
  }
}
@media (min-width: 960px) and (min-width: 1280px) {
  #dedi-area:before {
    left: calc(((100% - 1200px) / 2) + 24px);
  }
}
@media (min-width: 960px) and (min-width: 1600px) {
  #dedi-area:before {
    left: calc(((100% - 1400px) / 2) + 24px);
  }
}
#dedi-area__text {
  margin-bottom: 20px;
}
@media (min-width: 720px) {
  #dedi-area__text {
    width: 83.3333333333%;
  }
}
#dedi-area__text .title {
  padding-right: 40px;
  margin-bottom: 15px;
}
@media (min-width: 720px) {
  #dedi-area__text .title {
    padding-right: 0;
  }
}
@media (min-width: 720px) {
  #dedi-area__text {
    width: 33.3333333333%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 720px) {
  #dedi-area__text {
    width: 41.6666666667%;
    padding-right: 6px;
    padding-left: 0;
  }
}
@media (min-width: 720px) and (min-width: 720px) {
  #dedi-area__text {
    padding-right: 12px;
  }
}
#dedi-area__links {
  position: relative;
  z-index: 2;
}
@media (min-width: 720px) {
  #dedi-area__links {
    width: 50%;
    padding-left: 6px;
    padding-right: 0;
    margin-right: 8.3333333333%;
    margin-left: auto;
  }
}
@media (min-width: 720px) and (min-width: 720px) {
  #dedi-area__links {
    padding-left: 12px;
  }
}
@media (min-width: 1600px) {
  #dedi-area__links {
    width: 41.6666666667%;
    padding-left: 6px;
    padding-right: 0;
    margin-right: 25%;
  }
}
@media (min-width: 1600px) and (min-width: 720px) {
  #dedi-area__links {
    padding-left: 12px;
  }
}
#dedi-area__in {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (min-width: 720px) {
  #dedi-area__in {
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    justify-content: flex-start;
  }
}
@media (min-width: 960px) {
  #dedi-area__in {
    margin-left: 8.3333333333%;
    padding: 80px 0;
  }
}
@media (min-width: 1280px) {
  #dedi-area__in {
    padding: 120px 0;
  }
}
@media (min-width: 1600px) {
  #dedi-area__in {
    position: relative;
  }
}
#dedi-area__img {
  display: none;
}
@media (min-width: 720px) {
  #dedi-area__img {
    display: block;
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: -45px;
    right: 0;
    width: 310px;
    background-color: #1c1a36;
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
  }
}
@media (min-width: 960px) {
  #dedi-area__img {
    top: -55px;
    bottom: -55px;
    width: 420px;
  }
}
@media (min-width: 1280px) {
  #dedi-area__img {
    right: -120px;
    width: 600px;
    top: -40px;
    bottom: -40px;
  }
}
#dedi-area__img__inner {
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.w-commitment {
  position: relative;
  padding: 40px 0;
}
@media (min-width: 720px) {
  .w-commitment {
    padding-top: 110px;
    padding-bottom: 60px;
  }
}
@media (min-width: 960px) {
  .w-commitment {
    padding-top: 120px;
    padding-bottom: 80px;
  }
}
@media (min-width: 1280px) {
  .w-commitment {
    padding: 120px 0;
  }
}
.w-commitment:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: #EBEBEB;
  z-index: -1;
}
@media (min-width: 960px) {
  .w-commitment:before {
    height: 60%;
  }
}
.w-commitment .container {
  position: relative;
}
.w-commitment .container:after {
  content: "";
  position: absolute;
  height: 1px;
  background: #C7CBCF;
  left: 12px;
  right: 12px;
  bottom: -40px;
}
@media (min-width: 720px) {
  .w-commitment .container:after {
    bottom: -60px;
  }
}
@media (max-width: 959.98px) {
  .w-commitment .row {
    justify-content: center;
  }
}
.w-commitment .commitment-pages {
  width: 100%;
}
@media (min-width: 720px) {
  .w-commitment .commitment-pages {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (min-width: 960px) {
  .w-commitment .commitment-pages {
    width: 66.6666666667%;
    padding-left: 0;
    padding-right: 0;
  }
}
.w-commitment .commitment-text {
  width: 100%;
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 40px;
}
@media (min-width: 720px) {
  .w-commitment .commitment-text {
    padding-left: 12px;
  }
}
@media (min-width: 720px) {
  .w-commitment .commitment-text {
    padding-right: 12px;
  }
}
@media (min-width: 720px) {
  .w-commitment .commitment-text {
    margin-left: 16.6666666667%;
    width: 66.6666666667%;
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (min-width: 720px) and (min-width: 720px) {
  .w-commitment .commitment-text {
    padding-left: 12px;
  }
}
@media (min-width: 720px) and (min-width: 720px) {
  .w-commitment .commitment-text {
    padding-right: 12px;
  }
}
@media (min-width: 960px) {
  .w-commitment .commitment-text {
    margin-left: 0%;
    width: 33.3333333333%;
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  .w-commitment .commitment-text {
    padding-left: 12px;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  .w-commitment .commitment-text {
    padding-right: 12px;
  }
}
@media (min-width: 1280px) {
  .w-commitment .commitment-text {
    padding-left: calc(12px + 55px);
  }
}
.w-commitment .commitment-text .title {
  padding-bottom: 15px;
}
.w-commitment .commitment-text .caption {
  padding-bottom: 15px;
}
.w-commitment .slider {
  width: 100%;
  position: relative;
  padding-bottom: 56px;
}
@media (min-width: 960px) {
  .w-commitment .slider {
    padding-bottom: 64px;
  }
}
.w-commitment .slider .slick-track {
  display: flex;
}
.w-commitment .slider .slick-slide {
  height: auto;
  margin: 6px;
}
@media (min-width: 720px) {
  .w-commitment .slider .slick-slide {
    margin: 12px;
  }
}
.w-commitment .slider .slick-slide > div {
  height: 100%;
}
.w-commitment .slider .slick-slide > div .hilight-page {
  height: 100%;
}
.w-commitment .slider .prev, .w-commitment .slider .next, .w-commitment .slider .slick-dots {
  position: absolute;
  bottom: 0;
}
.w-commitment .slider .prev, .w-commitment .slider .next {
  border: 1px solid #A6B0B9;
  border-radius: 50%;
  padding: 16px;
  cursor: pointer;
  z-index: 99;
  transition: all 250ms ease-out;
}
.w-commitment .slider .prev i, .w-commitment .slider .next i {
  color: #A6B0B9;
}
.w-commitment .slider .prev i, .w-commitment .slider .next i {
  transition: all 250ms ease-out;
  font-size: 18px;
  line-height: 18px;
}
@media (min-width: 960px) {
  .w-commitment .slider .prev i, .w-commitment .slider .next i {
    font-size: 24px;
    line-height: 24px;
  }
}
.w-commitment .slider .prev:before, .w-commitment .slider .next:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 50%;
  transition: all 250ms ease-out;
}
@media (min-width: 960px) {
  .w-commitment .slider .prev:hover, .w-commitment .slider .next:hover {
    border-color: transparent;
  }
  .w-commitment .slider .prev:hover:before, .w-commitment .slider .next:hover:before {
    border-color: #616B75;
  }
}
.w-commitment .slider .prev {
  right: 80px;
}
@media (min-width: 960px) {
  .w-commitment .slider .prev {
    right: 88px;
  }
}
.w-commitment .slider .next {
  right: 6px;
}
@media (min-width: 960px) {
  .w-commitment .slider .next {
    right: 12px;
  }
}

.climat {
  position: relative;
}
@media (min-width: 1280px) {
  .climat {
    padding-bottom: 120px;
  }
}
.climat:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: #EBEBEB;
  z-index: -1;
  width: 100%;
}
@media (min-width: 960px) {
  .climat:before {
    height: 30%;
  }
}
.climat .main-title {
  text-align: center;
  margin-bottom: 20px;
}
@media (min-width: 960px) {
  .climat .main-title {
    margin-bottom: 40px;
  }
}
.climat .box .inner {
  background-color: #fff;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
  padding: 16px;
  height: 100%;
}
@media (min-width: 960px) {
  .climat .box .inner {
    padding: 32px;
  }
}
.climat .gaz:before {
  content: none;
}
@media (min-width: 960px) {
  .climat .gaz {
    width: 58.3333333333%;
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  .climat .gaz {
    padding-left: 12px;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  .climat .gaz {
    padding-right: 12px;
  }
}
.climat .gaz .title {
  margin-bottom: 4px;
}
.climat .gaz .data {
  margin-top: 20px;
}
.climat .gaz .data .source {
  font-style: italic;
}
.climat .gaz .data .source:not(.last) {
  text-align: center;
}
.climat .gaz .data .label {
  font-weight: bold;
}
.climat .gaz .data .label span {
  color: #3BC6E5;
}
.climat .gaz .data .label + .label {
  margin-top: 8px;
}
.climat .gaz .data .label.last {
  margin-top: 16px;
}
.climat .gaz .data .link {
  display: inline-block;
  margin-top: 20px;
}
.climat .air:before {
  content: none;
}
@media (max-width: 959.98px) {
  .climat .air {
    margin: 40px auto;
  }
}
@media (min-width: 960px) {
  .climat .air {
    width: 41.6666666667%;
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  .climat .air {
    padding-left: 12px;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  .climat .air {
    padding-right: 12px;
  }
}
.climat .air .title {
  margin-bottom: 24px;
  text-align: center;
}
.climat .air iframe {
  width: 100%;
  height: 400px;
}
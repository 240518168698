.w-commitment{
  position: relative;
  padding: paddingFlex(s) 0;

  @include breakpoint(sm){
    padding-top: rem(110);
    padding-bottom: paddingFlex(m) ;
  }

  @include breakpoint(md){
    padding-top: rem(120);
    padding-bottom:paddingFlex(l);
  }

  @include breakpoint(lg){
    padding: rem(120) 0 ;
  }

  &:before{
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    background:$gray-04;
    z-index: -1;

    @include breakpoint(md){
      height: 60%;
    }

  }

  .container{
    position: relative;
    &:after{
      content:"";
      position: absolute;
      height: 1px;
      background:$gray-03;
      left: rem(12);
      right: rem(12);
      bottom:rem(-40);
      @include breakpoint(sm){
        bottom:rem(-60);
      }
    }
  }

  .row{
    @include breakpoint-max(md) {
      justify-content: center;
    }
  }

  .commitment-pages{
    width: 100%;
    @include breakpoint(sm){
      display: flex;
      flex-wrap: wrap;
    }
    @include breakpoint(md){
      @include col(8,12,false);
    }
  }

  .commitment-text{
    @include col(12);
    margin-top: rem(40);

    @include breakpoint(sm){
      @include offset(2,12);
      @include col(8);
    }
    @include breakpoint(md){
      @include offset(0);
      @include col(4,12,true);
    }

    @include breakpoint(lg){
      padding-left: calc(#{rem(12)} + #{rem(55)});
    }

    .title{
      padding-bottom: rem(15);
    }
    .caption{
      @extend %p;
      padding-bottom: rem(15);
    }
  }

  .slider {
    width: 100%;
    position: relative;
    padding-bottom: rem(56);

    @include breakpoint(md) {
      padding-bottom: rem(64);
    }

    .slick-track {
      display: flex;
    }

    .slick-slide {
      height: auto;
      margin: rem(6);

      @include breakpoint(sm) {
        margin: rem(12);
      }

      > div {
        height: 100%;

        .hilight-page {
          height: 100%;
        }
      }
    }

    .prev, .next, .slick-dots {
      position: absolute;
      bottom: 0;
    }

    .prev, .next {
      border: rem(1) solid $gray-02;
      border-radius: 50%;
      padding: rem(16);
      cursor: pointer;
      z-index: 99;
      @include transition_default;
      i{
        color:$gray-02;
      }


      i {
        @include transition_default;
        @include font-size(rem(18));

        @include breakpoint(md) {
          @include font-size(rem(24));
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: rem(2) solid transparent;
        border-radius: 50%;
        @include transition_default;
      }

      @include breakpoint(md) {

        &:hover {
          border-color: transparent;
          &:before {
            border-color: $gray-01;
          }
        }
      }
    }

    .prev {
      right: rem(80);
      @include breakpoint(md) {
        right: rem(88);
      }
    }

    .next {
      right: rem(6);

      @include breakpoint(md) {
        right: rem(12);
      }
    }
  }


}

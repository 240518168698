@use "sass:math";

#home-slider {

  background-color: $primary-color;


  height: 83.5vh;

  min-height: rem(480);
  position: relative;
  overflow: hidden;

  max-height: rem(576);

  @include breakpoint-between(sm, md) {
    height: 43.5vh;
  }

  @include breakpoint(md) {
    max-height: rem(720);
  }



  [js-slider]{
    opacity: 0;
    &.ready{
      opacity: 1;
    }
  }


  .home-slider__slide{

    height:83.5vh;
    min-height:rem(480);

    max-height:rem(576);

    @include breakpoint-between(sm, md){
      height:43.5vh;
    }

    @include breakpoint(md){
      align-items: flex-end;
      max-height: rem(720);
    }

    position: relative;

    display: flex;
    align-items: flex-end;

    //z-index: 999 !important;


    &__in{
      z-index: 2;
      position: relative;

      padding-left:rem(85);

      padding-bottom: rem(180);

      @include breakpoint(sm){

        padding-left:rem(150);
        padding-bottom: rem(216);
      }

      @include breakpoint(md) {
        padding-bottom: rem(100);
        @include container-max-widths();
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        padding-left: math.percentage(math.div(1, 12));
      }



    }

    &__title{
      margin-bottom:rem(20);
      max-width:rem(500);
      @include breakpoint(sm){
        margin-bottom:rem(30);
      }

      &__top, &__main{
        transform: translate3d(0, rem(20), 0);
        opacity:0;
      }
    }

    &__btn-w{
      transform: translate3d(0, rem(-20), 0);
      opacity:0;
    }



    &__img-w{
      &:after{
        content:"";
        z-index: 2;
        @include absolute-full;

        background-color:primary-color(0.85);

        @include breakpoint(md) {

        background:linear-gradient(180deg, rgba(28, 26, 54, 0) 12.17%, rgba(28, 26, 54, 0) 12.17%, rgba(28, 26, 54, 0.65) 48.48%, rgba(28, 26, 54, 0.85) 70.71%, rgba(28, 26, 54, 0.9) 100%);

        }
      }
    }

    &__img-w, &__img {
      @include absolute-full;
    }

    &__img{
      z-index: 2;

      background-size: cover;
      background-repeat: no-repeat;

      //transition: opacity 500ms ease-in-out;

      @include transition-default;


      transform-origin: bottom;

      transform: scale(1.2);
      //opacity: 0;

    }



    &.active{
      .home-slider__slide__img{
        transform: scale(1);

        @include transition(800ms);
      }

      .home-slider__slide__title__top, .home-slider__slide__title__main, .home-slider__slide__btn-w{
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition-delay:100ms;
        //transition: all 800ms ease-in-out;
        @include transition(500ms);
        transition-property: opacity, transform;
        transition-delay:300ms;
      }

    }
    &.leave{
      .home-slider__slide__img {
        transform: scale(1);
      }
    }



  }

  &.slick-dotted.slick-slider{
    margin-bottom: 0;
  }

  .slick-dots{
    bottom: rem(180);
    overflow: hidden;
    top:0;
    display:flex ;
    flex-direction: column;
    justify-content: flex-start;
    pointer-events: none;

    padding-top:rem(110);

    padding-left: padding(l);

    @include breakpoint(sm){
      bottom:0;
      padding-left:rem(24);
    }


    @include breakpoint(md){
      @include container-max-widths();
      @include container();
      left:calc(50% - 22px);
      transform:translate3d(-50%,0,0);
      padding-top:rem(200);

    }

    @include breakpoint(lg){
      padding-top:rem(150);
    }


    li{

      pointer-events: auto;
      margin:0 0 rem(-5);
      width: rem(48);
      height:rem(48);
      position: relative;


      &.active{
        .page{
          border-color:rgba(255,255,255,0.25);
          color:$secondary-color;

          .arc{
            opacity:1;
            &__left{
              top:rem(0);
            }

            &__right{
              bottom:0;
            }
          }
        }
      }

      &:first-child{
        &:before{
          content: "";
          width:rem(1);
          background-color: rgba(255,255,255,0.45);
          position: absolute;
          bottom: calc(100% + 10px);
          top:rem(-9999);
          left: 50%;
        }
      }
      &:last-child{
        &:after{
          content: "";
          width:rem(1);
          background-color: rgba(255,255,255,0.45);
          position: absolute;
          top: calc(100% + 10px);
          bottom:rem(-9999);
          left: 50%;
        }
      }

      &:hover{
        .page{
          color: $secondary-color;
        }
      }

    }




    .page{
      font-weight: bold;
      color:white;
      @include font-size(rem(18));
      width: rem(48);
      height:rem(48);
      background-color:transparent;
      border:rem(1) solid transparent;
      //border-color:rgba(255,255,255,0.25);
      border-radius: 50%;

      @include transition-default;

      position: relative;

      .arc{
        position: absolute;
        @include transition-default;
        top:rem(-1);
        left:rem(-1);
        right:rem(-1);
        bottom:rem(-1);
        opacity:0;
        transform-origin:center;

        &__left, &__right{
          position:absolute;
          top:rem(0);
          width: rem(24);
          overflow: hidden;

          &:before{
            content: "";
            width: rem(48);
            height:rem(48);
            position:absolute;
            top:0;
            right:0;
            bottom: 0;
            border:$secondary-color solid rem(1);
            border-radius: 50%;
          }
        }

        &__left{
          top:rem(48);
          left:0;
          bottom: rem(0);
          transform:rotate(180deg);


          transition:top 2400ms linear;
          transition-delay: 2400ms;
        }


        &__right{

          right:0;
          bottom: rem(48);

          transition:bottom 2400ms linear;

        }
      }

      &:before{
        content: none;
      }
    }
  }




  #home-slider-cta{
    position: absolute;
    bottom: 0;

    left: 0;
    right: 0;
    padding-bottom: rem(20);

    padding-left:padding(l);

    pointer-events: none;

    @include breakpoint(sm){

      padding-left:rem(150);
      padding-bottom: rem(45);
    }

    @include breakpoint(md) {

      @include container-max-widths();

      padding-left: 0;

      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      padding-bottom: rem(105);

      overflow: hidden;
    }



    .home-slider-cta__item{
      margin-bottom:rem(10);

      @include breakpoint(md) {
        @include col(4,12);
      }
      @include breakpoint(lg) {
        @include col(3,12);
      }
    }

    .home-slider-cta__link{
      color:white;

      display: flex;
      flex-direction: row;
      align-items: center;

      pointer-events: auto;

      @include font-size(rem(17));

      @include transition-default;

      &:hover{
        color:$secondary-color;

        .home-slider-cta__icon{
          border-color:$secondary-color;
        }
      }

    }

    .home-slider-cta__icon{
      width: rem(56);
      height:rem(56);
      display: flex;
      justify-content: center;
      align-items: center;
      border: rem(1) solid rgba(255,255,255,0.25);
      margin-right:rem(20);
      border-radius: 50%;

      transition: border-color 200ms ease-out;

      .icon{
        @include font-size(rem(24))
      }
    }


    .home-slider-cta__txt{
      position: relative;

      @include breakpoint(md) {
        &:after {
          content: "";
          position: absolute;
          height: rem(1);
          left: calc(100% + 20px);
          right: rem(-9999);
          top: 50%;
          background-color: rgba(255, 255, 255, 0.25);
        }
      }
    }

  }


}

//quick fix after a infinite number of tickets, not very clever, sorry
//md
@media (min-height: 600px) and (min-width: 960px) {
  #home-slider .slick-dots{
    padding-top:rem(250);
  }
}
@media (min-height: 700px) and (min-width: 960px) {
  #home-slider .slick-dots{
    padding-top:rem(350);
  }
}
@media (min-height: 700px) and (min-width: 960px) {
  #home-slider .slick-dots{
    padding-top:rem(350);
  }
}
@media (min-height: 800px) and (min-width: 960px) {
  #home-slider .slick-dots{
    padding-top:rem(420);
  }
}
//lg
@media (min-height: 600px) and (min-width: 1280px) {
  #home-slider .slick-dots{
    padding-top:rem(150);
  }
}
@media (min-height: 700px) and (min-width: 1280px) {
  #home-slider .slick-dots{
    //padding-top:rem(350);
    padding-top:rem(250);
  }
}
@media (min-height: 800px) and (min-width: 1280px) {
  #home-slider .slick-dots{
    //padding-top:rem(420);
    padding-top:rem(350);
  }
}

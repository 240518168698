#articles-sections{
  position: relative;
  padding: paddingFlex(s) 0;
  @include breakpoint(xsm){
    padding-bottom:rem(20);
  }
  @include breakpoint(sm){
    padding: paddingFlex(m) 0 rem(20);
  }
  @include breakpoint(lg){
    padding: paddingFlex(l) 0 rem(35);
  }
  &:before{
    @include line-left;
  }

  .wrap{
    @include breakpoint(sm){
      @include offset(1);
    }
  }
}

#articles-sections__head{

  margin-bottom:rem(30);

  .title{
    margin-bottom:rem(10);
    @include breakpoint(xsm){
      margin-bottom:rem(0);
    }
  }

  @include breakpoint(xsm){
    display: flex;
    justify-content: space-between;
    align-items: center;


    .btn{
      position: relative;
      top:rem(10);
    }
  }

}



.articles-list{
  display: flex;
  flex-direction: column;

  //margin-top:rem(30);


  @include breakpoint(xsm){
    flex-direction: row;
    flex-wrap: wrap;
  }

  &.row{
    @include breakpoint(lg){
      margin-right:rem(-22);
      margin-left:rem(-22);

      .article-card{
        padding-left:rem(22);
        padding-right:rem(22);
        margin-bottom:rem(45);
        min-height:rem(198);
      }
    }
  }

}

.article-card{
  margin-bottom:rem(20);
  display: flex;
  width: 100%;
  @include gutterize();

  @include breakpoint(sm){
    margin-bottom: rem(40);
  }


  &:last-child{
    margin-bottom:0;
    @include breakpoint(xsm){
      margin-bottom:rem(20);
    }
    @include breakpoint(sm){
      margin-bottom: rem(40);
    }
  }


  &__in{
    box-shadow: $box-shadow;
    position: relative;

    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    @include breakpoint(md){
      flex-direction: row;

    }


    &:hover{
      .article-card__title{
        color:$secondary-color;
      }

      .btn{
          color:$secondary-color;
          &:before{
            opacity: 1;
          }

      }
    }
  }



  &__img-w{
    padding-top:54.8%;
    height:0;

    background-color:$primary-color;

    position: relative;

    @include breakpoint(md){
      height:100%;
      @include col(2.7, 6,false);
      padding-top:0;

    }
    @include breakpoint(lg){
      @include col(2.1, 6,false);
    }
  }

  &__img{
    @include absolute-full;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__video{
    width: rem(80);
    height: rem(80);
    border-radius: rem(50);
    background:$white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    @include center-both;

    @include breakpoint(md){
      width: rem(55);
      height: rem(55);
    }

    .icon{
      font-size: rem(35);
      @include text-gradient;
    }
  }


  &__date{
    text-transform: uppercase;
    color:$gray-01;
    @include font-size(rem(15));
  }

  &__title{
    font-weight: 600;
    @include font-size(rem(19), rem(26));

    min-height:rem(60);

    margin-bottom: rem(20);

    color:$primary-color;

    @include transition-default;

    @include breakpoint(xsm){
      flex: 1 0 auto;
    }

    @include breakpoint(md) {
      @include font-size(rem(21), rem(28));
    }

  }

  &__text{
    padding:rem(20);
    @include breakpoint(xsm){
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
    }

    @include breakpoint(md){
      flex:auto;
      @include col(3.3, 6,false);
      padding-left:rem(30);
      padding-right:rem(30);
    }
    @include breakpoint(lg){
      @include col(3.9, 6,false);
      padding-left:rem(30);
      padding-right:rem(30);
    }
  }



  @include breakpoint(xsm){
    @include col(6,12, true);
  }

}

//particular
.home{
  #articles-sections{
    @include breakpoint(md){
      padding-top: rem(40);
    }
    &:before{
      display: none;
    }

    .wrap{
      margin-left: 0;
    }
  }
}


/* Safari 10.1+ */

.article-card__video .icon{
  border-radius:50%;
  overflow: hidden;
}
.article-card__video .icon:before{
  @include text-gradient;
  width:rem(55);
  height:rem(55);
  display:inline-block;
  padding-top:rem(10);
  padding-left:rem(3);
  text-align: center;
}
.hilight-page{

  .w-page{
    position: relative;
    padding:rem(155) rem(25)  rem(25);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    overflow: hidden;
    &:before{
      @include gradient-pseudo(linear-gradient(360deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%));
      z-index: 0;
    }
    &:after{
      @include gradient-pseudo;
      z-index: -1;
      opacity: 0;
      @include transition-default;
    }
    @include breakpoint(sm){
      padding-top: rem(265);
    }

    @include breakpoint(md){
      padding-top: rem(165);
    }

    @include breakpoint(lg){
      padding-top: rem(265);
    }

    @include breakpoint(xlg){
      padding-top: rem(400);
    }

    .bg{
      @include bg-center;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      transform: scale(1,1);
      transition : all 300ms cubic-bezier(0.04, 0.19, 0.56, 0.99);
    }

    .headline{
      @include front;
    }

    .title{
      padding-bottom: rem(10);
      @include front;
    }
    .caption{
      display: none;
      @include front;
      @extend %p;
      @include breakpoint(sm){
        display: block;
        padding-bottom: rem(25);
      }
    }

    .btn{
      align-self :flex-end;
    }

    //hover
    &:hover{
      @include breakpoint(md) {
        .bg {
          transform: scale(1.1);
        }
        &:after {
          opacity: .7;
        }
      }
    }
  }
}

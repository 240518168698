.climat {
	position: relative;
	@include breakpoint(lg) {
		padding-bottom: rem(120);
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 50%;
		background: $gray-04;
		z-index: -1;
		width: 100%;

		@include breakpoint(md) {
			height: 30%;
		}

	}

	.main-title {
		text-align: center;
		margin-bottom: rem(20);
		@include breakpoint(md) {
			margin-bottom: rem(40);
		}
	}


	.box {
		.inner {
			background-color: $white;
			box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
			padding: rem(16);
			height: 100%;

			@include breakpoint(md) {
				padding: rem(32);
			}
		}
	}

	.gaz {
		&:before {
			content: none
		}

		@include breakpoint(md) {
			@include col(7, 12);
		}

		.title {
			margin-bottom: rem(4);
		}

		.data {
			margin-top: rem(20);
			.source {
				font-style: italic;
				@extend %p-s;

				&:not(.last){
					text-align:center;
				}

			}

			.label{
				font-weight: bold;
				span{
					color: $secondary-color;
				}

				& + .label{
					margin-top: rem(8);
				}

				&.last{
					margin-top: rem(16);
				}
			}

			.link{
				display:inline-block;
				margin-top: rem(20);
			}
		}
	}

	.air {
		&:before {
			content: none
		}

		@include breakpoint-max(md) {
			margin: rem(40) auto;
		}
		@include breakpoint(md) {
			@include col(5, 12);
		}

		.title {
			margin-bottom: rem(24);
			text-align: center;
		}

		iframe {
			width: 100%;
			height: rem(400);
		}
	}

}
#home-highlight {
	.container {
		@include breakpoint-max(md) {
			padding: 0;
		}
	}

	.row {
		@include breakpoint(md) {
			margin-top: rem(-60);
			padding-bottom: rem(80);
		}

		.distributor {
			padding: rem(40) rem(16);
			text-align: center;
			background-image: url("../../static/img/bg/home_highlight.jpg");
			background-repeat: no-repeat;
			background-size: 60%;
			z-index: 1;
			background-color: $white;
			box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.15);

			@include breakpoint(sm) {
				padding-top: rem(45);
				padding-bottom: rem(45);
			}
			@include breakpoint(md) {
				padding: rem(60) rem(120);
				@include col(8);
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			.titles {
				padding-bottom: rem(15);
			}

			.caption {
				@extend %p;
			}

			.btn-w {
				margin-top: rem(25);
			}
		}


	}

	.my-space {
		z-index: 1  ;
		@include breakpoint(md) {
			@include col(4);
		}

		.w-page {
			padding: rem(75) rem(16);
			box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.15);
			@include breakpoint(md) {
				padding: rem(38) rem(20);
			}
			@include breakpoint(lg) {
				padding: rem(68) rem(40);
			}

			&:before {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(360deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
			}

			.headline {
				margin: 0 auto;
			}

			.title {
				z-index: 1;
				margin-bottom: rem(24);
				margin-top: rem(4);
				text-align: center;
			}

			.btn {
				align-self: center;
			}
		}
	}

}
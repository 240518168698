@use "sass:math";
#dedi-area{

  position: relative;


  @include breakpoint(md) {
    &:before {
      @include line-left;
      bottom: 34%;
    }
  }

  &__text {
    margin-bottom: rem(20);

    @include breakpoint(sm) {
      width: math.percentage(math.div(10, 12));
    }

    .title {
      padding-right: rem(40);
      margin-bottom: rem(15);
      @include breakpoint(sm) {
        padding-right: rem(0);
      }
    }

    @include breakpoint(sm) {
      @include col(4, 12, false);
    }
    @include breakpoint(sm) {
      @include col(5, 12, 'right');
    }
  }

  &__links{
    position:relative;
    z-index:2;
    @include breakpoint(sm) {
      @include col(6, 12, 'left');
      margin-right: math.percentage(math.div(1, 12));
      margin-left: auto;
    }
    @include breakpoint(xlg) {
      @include col(5, 12, 'left');
      margin-right: math.percentage(math.div(3, 12));
    }
  }

  &__in {
    padding-top:paddingFlex(s);
    padding-bottom:paddingFlex(s);

    @include breakpoint(sm) {

      padding-top:paddingFlex(m);
      padding-bottom:paddingFlex(m);

      display: flex;

      justify-content: flex-start;
      //position:relative;
    }

    @include breakpoint(md){
      @include offset(1,12);
      padding:paddingFlex(l) 0;
    }

    @include breakpoint(lg){
      padding: rem(120) 0 ;
    }

    @include breakpoint(xlg) {
      position: relative;
    }
  }

  &__img{
    display: none;

    @include breakpoint(sm) {
      display: block;
      z-index:1;
      position:absolute;
      top:0;
      bottom:rem(-45);
      right:rem(0);
      width: rem(310);
     // background-size: rem(425) auto;
      background-color:$primary-color;
      background-size: cover;
      background-position: 0 0;
      background-repeat: no-repeat;
    }
    @include breakpoint(md) {
      top:rem(-55);
      bottom:rem(-55);
      width: rem(420);
      //background-size: rem(550) auto;
    }

    @include breakpoint(lg) {
      right:rem(-120);
      width: rem(600);
      top:rem(-40);
      bottom:rem(-40);
    }

    &__inner{
      background-size: cover;
      background-position: 0 0;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      height: 100%;
      @include center(both);
      //transition: opacity 250ms ease;
    }
  }


  .caption{
    @extend %p;
  }
}

%p{
  @include font-size(rem(17), rem(28));
  font-weight: normal;
}

%p-n{
  @include font-size(rem(17), rem(28));
  font-weight: normal;
}


%p-l{
  @include font-size(rem(21), rem(36));
  font-weight: normal;
  @include breakpoint(sm){
    @include font-size(rem(24), rem(41));
  }
}

%p-m{
  @include font-size(rem(19), rem(30));
  font-weight: normal;
  @include breakpoint(sm) {
    @include font-size(rem(20), rem(30));
  }
}


%p-s{
  @include font-size(rem(15), normal);
  font-weight: normal;
}
